import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FarmacovigilanzaComponent } from './farmacovigilanza/farmacovigilanza.component';
const routes: Routes =[
    { path: '',                 component: HomeComponent },
    { path: 'privacy-policy',   component: PrivacyPolicyComponent },
    { path: 'farmacovigilanza', component: FarmacovigilanzaComponent },
    { path: '', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
    useHash: false,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 0] // [x, y] 
    ,
    initialNavigation: 'enabledNonBlocking'
})
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
