import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { AppModule } from './app.module';

@NgModule({
  imports: [
    AppRoutingModule,
    AppModule,
  ],
  providers: [provideClientHydration()],
  bootstrap: [AppComponent]
})
export class AppBrowserModule { }
