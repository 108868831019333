import { Component, AfterViewInit, Injectable, Inject, Renderer2, ElementRef, ViewChild, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { LocationStrategy, PlatformLocation, Location, isPlatformBrowser } from '@angular/common';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
    @ViewChild(NavbarComponent,{static:false}) navbar: NavbarComponent;

    constructor(
        private renderer: Renderer2,
        private router: Router,
        @Inject(DOCUMENT) private document: any,
        private element: ElementRef,
        public location: Location,
        @Inject(PLATFORM_ID) private platform: any
    ) {
        if (isPlatformBrowser(this.platform)) {
            // Subscribe to router events and send page views to Google Analytics
            const navEndEvents = router.events.pipe(
                filter(event => event instanceof NavigationEnd),
            );
        }
    }
    ngAfterViewInit() {
        if (isPlatformBrowser(this.platform)) {

            var ua = window.navigator.userAgent;
            var trident = ua.indexOf('Trident/');
            if (trident > 0) {
                // IE 11 => return version number
                var rv = ua.indexOf('rv:');
                var version = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
            }
            if (version) {
                var body = document.getElementsByTagName('body')[0];
            }
        }
    }

    removeFooter() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        titlee = titlee.slice(1);
        if (titlee === 'signup' || titlee === 'nucleoicons') {
            return false;
        }
        else {
            return true;
        }
    }
}
