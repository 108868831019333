import { Directive, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[gaEventTracker]'
})
export class GaEventDirective {

    @Input('gaEventTracker') option: any;
    @HostListener('click', ['$event']) onClick($event) {
        (<any>window).gtag('event', this.option.action, {
            'event_category': this.option.category,
            'event_label': this.option.label
        });
    }
    constructor() { }
}